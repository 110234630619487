import React from "react";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import CTA from "../components/CTA";
import IntroNext from "../components/IntroNext";
import FeatureDivider from "../components/FeatureDivider";
import Styles from "../components/Styles";
import SocialBar from "../components/SocialBar";
import Gallery from "../components/Gallery";
import Difference from "../components/Difference";
import GoToTopButton from "../components/GoToTopButton";
import { useInView } from "react-intersection-observer";

function Home() {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <main>
      <Hero />
      <Intro />
      <CTA />
      <div ref={ref} style={{ opacity: "0", visibility: "hidden" }}></div>
      <IntroNext />
      <FeatureDivider />
      <Styles />
      <SocialBar />
      <Gallery />
      <Difference />
      <GoToTopButton className={inView ? "" : "hidden"} />
    </main>
  );
}

export default Home;
