import React from "react";
import Navigation from "./Navigation";
import NavigationMobile from "./NavigationMobile";

function Navbar() {
  return (
    <nav className="navbar">
      <Navigation />
      <NavigationMobile />
    </nav>
  );
}

export default Navbar;
