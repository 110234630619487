import React from "react";
import { useNavigate } from "react-router-dom";

function Contact() {
  const handleNavigate = () => {
    navigate("/gallery");
    window.scrollTo({ top: "0", behavior: "smooth" });
  };

  const navigate = useNavigate();

  return (
    <section id="section-contact" className="sectionContact">
      <div className="contactFlex">
        <div className="ctaWrapper">
          <h2 className="contactHeading">Skontaktuj się z nami</h2>
          <div className="formWrapper">
            <form
              id="contact-form"
              action="https://formspree.io/f/moqbkgwn"
              method="post"
              className="ctaForm"
            >
              <div className="ctaFormInputs">
                <input
                  type="text"
                  id="name-input"
                  name="name-input"
                  className="formInput"
                  placeholder="Imię"
                  autoComplete="off"
                  required
                />
                <input
                  type="email"
                  id="email-input"
                  name="email-input"
                  className="formInput"
                  placeholder="Email"
                  autoComplete="off"
                  required
                />
                <input
                  type="text"
                  id="subject-input"
                  name="subject-input"
                  className="formInput"
                  placeholder="Temat"
                  autoComplete="off"
                  required
                />
              </div>

              <textarea
                name="textarea"
                id="textarea"
                className="formTextarea"
                placeholder="Twoja Wiadomość"
                rows={5}
                autoComplete="off"
                required
              ></textarea>
            </form>
            <button
              form="contact-form"
              type="submit"
              className="contactFormButton"
            >
              Wyślij
            </button>
          </div>
        </div>
        <div className="contactTextbox">
          <div className="contactRow">
            <button onClick={handleNavigate} className="contactLinkHead">
              Galeria{" "}
            </button>
            <button onClick={handleNavigate} className="contactLink">
              Styl nowoczesny
            </button>
            <button onClick={handleNavigate} className="contactLink">
              Styl tradycyjny
            </button>
            <button onClick={handleNavigate} className="contactLink">
              Styl frezowany
            </button>
          </div>
          <div className="contactRow">
            <a href="#section-intro" className="contactLinkHead">
              O nas
            </a>
            <button onClick={handleNavigate} className="contactLink">
              Nasze prace
            </button>
            <a href="#section-styles" className="contactLink">
              Nasze Style
            </a>
          </div>
          <div className="contactRow">
            <button className="contactLinkHead">Dane Kontaktowe &darr;</button>
            <button className="contactLink">Grzegorz Bejger</button>
            <a
              href="mailto:bejgermanufaktura@gmail.com"
              className="contactLink"
            >
              bejgermanufaktura@gmail.com
            </a>
            <a href="tel:+48884989249" className="contactLink">
              +48 884 989 249
            </a>
            <button className="contactLink">NIP: 7441733416</button>
            <button className="contactLink">REGON: 522340594</button>
          </div>
          <div className="contactRow">
            <button className="contactLink">Pon—Pt: 09:00-17:00</button>
            <button className="contactLink">Sob: 10:00-14:00</button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
