import React from "react";
import one from "../assets/img/grid/1.jpg";
import two from "../assets/img/grid/2.jpg";
import three from "../assets/img/grid/3.jpg";
import four from "../assets/img/grid/4.jpg";
import five from "../assets/img/grid/5.jpg";
import six from "../assets/img/grid/6.jpg";
import seven from "../assets/img/grid/7.jpg";
import eight from "../assets/img/grid/8.jpg";
import nine from "../assets/img/grid/9.jpg";
import { TiTick } from "react-icons/ti";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

function Gallery() {
  const pics = [one, two, three, four, five, six, seven, eight, nine];
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/gallery");
    window.scrollTo({ top: "0", behavior: "smooth" });
  };

  return (
    <section className="gallery">
      <div className="galleryContent">
        <h2 className="galleryHeading">
          Nasze style wahają się od klasycznych po współczesne.
        </h2>
        <p className="galleryTextSub">
          Wykorzystujemy najwyższej jakości materiały do produkcji mebli w
          dębowych, orzechowych i ręcznie malowanych wykończeniach.
        </p>
        <div className="galleryFlex">
          <div className="galleryGrid">
            {pics.map((pic, i) => {
              return (
                <div key={i} className="galleryGridImg">
                  <img
                    className="galleryImg"
                    style={{ display: "block", width: "100%" }}
                    src={pic}
                    alt="Zdjęcie galerii"
                  />
                </div>
              );
            })}
          </div>
          <div className="galleryTextbox">
            <h3 className="gallerySubTitle">
              Nasz zespół poświęca czas na poznanie Ciebie!
            </h3>
            <p className="galleryTextSub">
              Zrozumienie Twojego stylu życia pozwoli naszym projektantom
              tworzyć koncepcje, które będą idealnym uzupełnieniem Twojego domu.
            </p>
            <ul className="galleryList">
              <p className="galleryListText">
                Oferujemy kompleksowe porady dotyczące:
              </p>
              <li className="galleryListItem">
                <TiTick className="galleryListIcon" />
                <span>Materiałów</span>
              </li>
              <li className="galleryListItem">
                <TiTick className="galleryListIcon" />
                <span>Wykończeń</span>
              </li>
              <li className="galleryListItem">
                <TiTick className="galleryListIcon" />
                <span>Wyposażenia</span>
              </li>
            </ul>
            <p className="galleryTextSub">
              Zapewniamy, że będziesz zachwycony efektem końcowym!
            </p>
            <button
              onClick={handleClick}
              ref={ref}
              className={`${
                inView ? "introNextButtonVisible" : "introNextButton"
              }`}
            >
              Odwiedź Galerię
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Gallery;
