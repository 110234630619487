import React from "react";
import { BsChevronDoubleUp } from "react-icons/bs";

function GoToTopButton({ className }) {
  const onClick = () => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  };

  return (
    <button onClick={onClick} className={`goToTopButton ${className}`}>
      <BsChevronDoubleUp
        className="bounce"
        style={{
          height: "24px",
          width: "24px",
          color: "var(--color-secondary)",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </button>
  );
}

export default GoToTopButton;
