import React from "react";
import { Link } from "react-router-dom";
import intro from "../assets/img/Intro.jpg";
import introSub from "../assets/img/IntroSub.jpg";
import { useInView } from "react-intersection-observer";

function Intro() {
  const { ref, inView } = useInView({
    threshold: 0.45,
    triggerOnce: true,
  });

  return (
    <section
      id="section-intro"
      style={{ backgroundColor: "var(--color-secondary)" }}
    >
      <div className="container">
        <div className="introContent">
          <div className="introTextbox">
            <h1 className={`headingPrimary ${inView ? "animateIn1" : ""}`}>
              Bejger Manufaktura, <br /> rodzinna firma <br /> z tradycjami
            </h1>
            <h2 className={`introSub ${inView ? "animateIn2" : ""}`}>
              Zajmujemy się projektowaniem, produkcją i instalacją mebli
              skrojonych na miarę Twoich potrzeb.
            </h2>
            <p className={`introText ${inView ? "animateIn3" : ""}`}>
              Dopasowujemy się do wnętrza i stylu życia naszych klientów. <br />{" "}
              <br />Z Twoim wyborem stylów, materiałów, kolorów i urządzeń,
              stworzymy praktyczne rozwiązania w estetycznie pięknych
              pomieszczeniach.
            </p>
            <div className="buttonGroup">
              <Link
                to="/gallery"
                className={`introButton ${inView ? "animateIn5" : ""}`}
              >
                Zobacz nasze prace
              </Link>
              <a
                href="#section-contact"
                className={`introButton ${inView ? "animateIn4" : ""}`}
              >
                Napisz do nas &darr;
              </a>
            </div>
          </div>
          <div className="introImage">
            <img
              style={{
                display: "block",
                width: "100%",
                transition: "all 0.3s ease-out",
              }}
              src={intro}
              alt="Intro"
            />
            <img
              style={{
                opacity: `${inView ? "1" : "0"}`,
                transition: "all 0.8s ease-out",
              }}
              ref={ref}
              src={introSub}
              alt="Intro"
              className="subImg"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Intro;
