import Header from "./layout/Header";
import Home from "./layout/Home";
import GalleryShow from "./pages/GalleryShow";
import Footer from "./layout/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/gallery" element={<GalleryShow />}></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
