import React from "react";
import NavCard from "./NavCard";
import card1 from "../assets/img/Card1.jpg";
import card2 from "../assets/img/Card2.jpg";
import card3 from "../assets/img/Card3.jpg";

function HeroNav() {
  return (
    <div className="heronav">
      <NavCard name="O nas" img={card1} link="#section-intro" />
      <NavCard name="Co nas odróżnia" img={card2} link="#section-difference" />
      <NavCard name="Style" img={card3} link="#section-styles" />
    </div>
  );
}

export default HeroNav;
