import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CiLink } from "react-icons/ci";

function StyleCard({ img, title, text }) {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/gallery");
    window.scrollTo({ top: "0", behavior: "smooth" });
  };

  return (
    <div className="styleCard">
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="styleCardImg"
      >
        <img
          style={{ display: "block", width: "100%" }}
          src={img}
          alt="Styl Nowoczesny"
        />
        <div
          className={
            isHovered ? "overlayCard" : "overlayCard overlayCardHidden"
          }
        >
          <button onClick={handleOnClick} className="overlayCardButton">
            <CiLink
              style={{
                height: "32px",
                width: "32px",
                color: "var(--color-cloud)",
              }}
            />
          </button>
        </div>
      </div>
      <div className="styleCardTextbox">
        <h3 className="styleCardTitle">{title}</h3>
        <p className="styleCardText">{text}</p>
      </div>
    </div>
  );
}

export default StyleCard;
