import React from "react";
import StyleCard from "./StyleCard";
import nowoczesny from "../assets/img/Nowoczesny.jpg";
import frezowany from "../assets/img/Frezowany.jpg";
import tradycyjny from "../assets/img/Tradycyjny.jpg";

function Styles() {
  const style = [
    {
      img: nowoczesny,
      title: "Nowoczesny z wąską ramką",
      text: "Drzwi zbudowane z wąskich drewnianych ramek i płyt meblowych, wykończone płaskimi panelami ozdobnymi i koroną w stylu nowoczesnym",
    },
    {
      img: tradycyjny,
      title: "Tradycyjny z ramą o przekroju prostokątnym",
      text: "Drzwi zbudowane z drewna i płyty meblowej, wykończone koroną tradycyjną frezowaną ozdobnym frezem",
    },
    {
      img: frezowany,
      title: "Tradycyjny z ramą frezowaną",
      text: "Drzwi zbudowane z drewna i płyty meblowej, wykończone panelami frezowanymi i koroną tradycyjną frezowaną ozdobnym frezem",
    },
  ];

  return (
    <section id="section-styles" className="sectionStyles">
      <div className="container">
        <div className="stylesTextbox">
          <h2 className="sectionHeading">Przejrzyj nasze kolekcje</h2>
          <p className="sectionText">
            Skontaktuj się z nami, a my stworzymy Twoje wymarzone wnętrze.
          </p>
          <p className="sectionTextSub">
            Niezależnie czy będzie to kuchnia, garderoba, pralnio-suszarnia czy
            jakiekolwiek pomieszczenie w Twoim domu. Dzięki szerokiej gamie
            stylów, kształtów i wzorów z pewnością znajdziesz kuchnię lub
            wnętrze, które pobudzi Twoją wyobraźnię. Czerp inspirację i stwórz
            własną idealną przestrzeń.
          </p>
        </div>
      </div>
      <div className="stylesContainer">
        {style.map((styl, i) => {
          return (
            <StyleCard
              key={i}
              img={styl.img}
              title={styl.title}
              text={styl.text}
            />
          );
        })}
      </div>
    </section>
  );
}

export default Styles;
