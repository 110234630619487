import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/bundle";

import slide1 from "../assets/img/SlideShow/1.JPG";
import slide2 from "../assets/img/SlideShow/2.JPG";
import slide3 from "../assets/img/SlideShow/3.JPG";
import slide4 from "../assets/img/SlideShow/4.JPG";
import slide5 from "../assets/img/SlideShow/5.JPG";
import slide6 from "../assets/img/SlideShow/6.JPG";
import slide7 from "../assets/img/SlideShow/7.JPG";
import slide8 from "../assets/img/SlideShow/8.JPG";
import slide9 from "../assets/img/SlideShow/9.JPG";
import slide10 from "../assets/img/SlideShow/10.JPG";
import slide11 from "../assets/img/SlideShow/11.JPG";
import slide12 from "../assets/img/SlideShow/12.JPG";
import slide13 from "../assets/img/SlideShow/13.JPG";
import slide14 from "../assets/img/SlideShow/14.JPG";
import slide16 from "../assets/img/SlideShow/16.JPG";
import slide17 from "../assets/img/SlideShow/17.JPG";
import slide18 from "../assets/img/SlideShow/18.JPG";
import slide20 from "../assets/img/SlideShow/20.JPG";
import slide21 from "../assets/img/SlideShow/21.JPG";
import slide22 from "../assets/img/SlideShow/22.JPG";
import slide24 from "../assets/img/SlideShow/24.JPG";
import slide25 from "../assets/img/SlideShow/25.JPG";
import slide26 from "../assets/img/SlideShow/26.JPG";
import slide28 from "../assets/img/SlideShow/28.JPG";
import slide29 from "../assets/img/SlideShow/29.JPG";
import slide30 from "../assets/img/SlideShow/30.JPG";

SwiperCore.use([Scrollbar, A11y]);

function GalleryShow() {
  const slides = [
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    slide9,
    slide10,
    slide11,
    slide12,
    slide13,
    slide14,
    slide16,
    slide17,
    slide18,
    slide20,
    slide21,
    slide22,
    slide24,
    slide25,
    slide26,
    slide28,
    slide29,
    slide30,
  ];

  return (
    <main>
      <section className="sectionGalleryShow">
        <div className="container">
          <h1 className="headingGalleryShow">Galeria</h1>

          <Swiper
            slidesPerView={1}
            scrollbar={{ draggable: true }}
            preventInteractionOnTransition={true}
          >
            {slides.map((slide, i) => (
              <SwiperSlide key={i}>
                <div
                  className="galleryShowSlider"
                  style={{
                    backgroundImage: `url(${slide})`,
                  }}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </main>
  );
}

export default GalleryShow;
