import React from "react";
import FeatureCard from "./FeatureCard";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { GoCalendar } from "react-icons/go";
import { GiWoodBeam } from "react-icons/gi";
import { ImSortAlphaAsc } from "react-icons/im";

function FeatureDivider() {
  const features = [
    {
      icon: <VscWorkspaceTrusted className="featIcon" />,
      text: "Najwyższa jakość usług",
      delay: 0.3,
    },
    {
      icon: <TbAdjustmentsHorizontal className="featIcon" />,
      text: "Indywidualne podejście do każdego klienta",
      delay: 0.6,
    },
    {
      icon: <GoCalendar className="featIcon" />,
      text: "Wiele lat doświadczenia",
      delay: 0.9,
    },
    {
      icon: <GiWoodBeam className="featIcon" />,
      text: "Najwyższa jakość materiałów",
      delay: 1.2,
    },
    {
      icon: <ImSortAlphaAsc className="featIcon" />,
      text: "Kompleksowe porady",
      delay: 1.5,
    },
  ];

  return (
    <div className="featDivider">
      {features.map((card, i) => (
        <FeatureCard key={i} icon={card.icon} text={card.text} />
      ))}
    </div>
  );
}

export default FeatureDivider;
