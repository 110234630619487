import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Parallax, Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/bundle";

import slide1 from "../assets/img/Slide1.JPG";
import slide2 from "../assets/img/Slide2.JPG";
import slide3 from "../assets/img/Slide3.JPG";

import HeroNav from "./HeroNav";
import Logo from "./Logo";

SwiperCore.use([Parallax, Autoplay, Scrollbar, A11y]);

function Hero() {
  const slideOne = {
    img: slide1,
    title: "Bejger Manufaktura",
    subtitle: "nie tylko kuchnie...",
    text: "",
  };
  const slideTwo = {
    img: slide2,
    title: "Rodzinna Firma",
    subtitle: "",
    text: "Dopasowujemy się do wnętrza i stylu życia naszych klientów.",
  };
  const slideThree = {
    img: slide3,
    title: "Najwyższa jakośc",
    subtitle: "",
    text: "Produkcja mebli w dębowych, orzechowych i ręcznie malowanych wykończeniach.",
  };

  const slides = [slideOne, slideTwo, slideThree];

  return (
    <section style={{ position: "relative" }}>
      <Logo />
      <Swiper
        slidesPerView={1}
        scrollbar={{ draggable: true }}
        autoplay={{ autoplay: true, delay: 5000 }}
        parallax={{ parallax: true }}
        preventInteractionOnTransition={true}
        speed={800}
      >
        {slides.map((slide, i) => (
          <SwiperSlide key={i}>
            <div
              style={{
                background: `url(${slide.img}) center no-repeat`,
                backgroundSize: "cover",
                minHeight: "75vh",
                position: "relative",
              }}
            >
              {slide.title !== "" && (
                <div className="textbox">
                  <h2 className="slide-text">{slide.title}</h2>

                  <p className="slide-text-sub">{slide.subtitle}</p>
                  <p className="slide-text-small">{slide.text}</p>
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <HeroNav />
    </section>
  );
}

export default Hero;
