import React from "react";
import { useNavigate } from "react-router-dom";
import grid1 from "../assets/img/GridDifference1.jpg";
import grid2 from "../assets/img/GridDifference2.jpg";
import grid3 from "../assets/img/GridDifference3.jpg";

function Difference() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/gallery");
    window.scrollTo({ top: "0", behavior: "smooth" });
  };

  return (
    <section id="section-difference" className="sectionDifference">
      <h2 className="differenceHeading">Co nas odróżnia?</h2>
      <div className="differenceGrid">
        <div id="gridCell1" className="differenceGridText2">
          <p className="differenceGridTitle2">
            Nasze drewno pochodzi z dobrze zarządzanych lasów, w których cykl
            wyrębu i ponownego sadzenia trwa nieprzerwanie od wielu pokoleń.
          </p>
          <p className="differenceGridPara2">
            Używamy wyłącznie miękkiego i twardego drewna rosnących w
            umiarkowanym klimacie i unikamy zagrożonych gatunków tropikalnych, w
            przypadku których praktyki pozyskiwania drewna są na ogół mniej
            kontrolowane i regulowane.
          </p>
        </div>
        <div
          id="gridCell2"
          style={{
            backgroundImage: `linear-gradient(to bottom right, rgba(0, 0, 0, 0.2), rgba(217, 175, 97, 0.2)), url(${grid1})`,
          }}
          className="differenceGridImg"
        ></div>

        <div
          id="gridCell3"
          style={{
            backgroundImage: `linear-gradient(to bottom right, rgba(217, 175, 97, 0.2), rgba(0, 0, 0, 0.2)), url(${grid2})`,
          }}
          className="differenceGridImg"
        ></div>
        <div id="gridCell4" className="differenceGridText">
          <p className="differenceGridTitle">
            W miarę możliwości używamy rodzimych gatunków drewna i sadzimy
            drzewo do każdego wykonanego projektu.
          </p>
          <p className="differenceGridPara">
            Podjęliśmy świadomą decyzję, aby używać wyłącznie farb wodnych co
            jest bardzo rzadkie w tej branży.
          </p>
          <button onClick={handleClick} className="differenceButton">
            Zobacz Zdjęcia &rarr;
          </button>
        </div>
        <div id="gridCell5" className="differenceGridText2">
          <p className="differenceGridTitle2">
            Niestrudzenie pracowaliśmy, aby wymyślić metodę, która sprawia, że
            używanie farby na bazie wody jest zarówno praktyczne w kuchni lub
            innym wnętrzu, jak i przyjazne dla środowiska.
          </p>
          <p className="differenceGridPara2">
            To wszystko są małe rzeczy, które możemy zrobić, aby zapewnić Ci
            meble, o których marzyłeś przy minimalnym wpływie na środowisko.
          </p>
          <a href="#section-contact" className="introNextButtonVisible">
            Skontaktuj się z nami &nbsp; &darr;
          </a>
        </div>
        <div
          id="gridCell6"
          style={{
            backgroundImage: `linear-gradient(to bottom right, rgba(0, 0, 0, 0.2), rgba(217, 175, 97, 0.2)), url(${grid3})`,
          }}
          className="differenceGridImg"
        ></div>
      </div>
    </section>
  );
}

export default Difference;
