import React, { useState } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function NavigationMobile() {
  const [isClicked, setIsClicked] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const onClick = () => {
    setIsClicked((prevState) => {
      return prevState ? false : true;
    });
  };

  const menuOnClick = () => {
    setMenuOpen((prevState) => {
      return prevState ? false : true;
    });
  };

  const updatePath = () => {
    if (window.location.pathname === "/gallery") {
      navigate("/");
    }

    setMenuOpen(false);
  };

  return (
    <div className="navbarMobile">
      <button onClick={menuOnClick} className="menuButton">
        <FiMenu className="menuButtonIcon" />
      </button>

      <div
        className={`navMobileWrapper ${menuOpen ? "navMobileWrapperOpen" : ""}`}
      >
        <ul className={`navigationMobile`}>
          <li>
            <Link
              onClick={() => setMenuOpen(false)}
              className="navigationLinkMobile"
              to="/"
            >
              Strona Główna
            </Link>
          </li>
          <li>
            <a
              onClick={updatePath}
              href="#section-intro"
              className="navigationLinkMobile"
            >
              O nas
            </a>
          </li>
          <li>
            <a
              onClick={updatePath}
              href="#section-styles"
              className="navigationLinkMobile"
            >
              Style
            </a>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpen(false)}
              className="navigationLinkMobile"
              to="/gallery"
            >
              Galeria
            </Link>
          </li>
          <li>
            <a
              onClick={updatePath}
              href="#section-difference"
              className="navigationLinkMobile"
            >
              Co nas odróżnia
            </a>
          </li>
          <li>
            <a
              onClick={() => setMenuOpen(false)}
              href="#section-contact"
              className="navigationLinkMobile"
            >
              Kontakt
            </a>
          </li>
        </ul>
      </div>

      <button onClick={onClick} className="contactButton">
        <BsFillTelephoneFill className="contactButtonIcon" />
      </button>

      <div
        className={`contactListWrapper ${
          isClicked ? "contactListWrapperOpen" : ""
        }`}
      >
        <ul className={`contactList`}>
          <li className="contactItem">
            <BsFillTelephoneFill className="contactButtonIcon" />
            <span style={{ color: "var(--color-text-dark)" }}>
              <a
                style={{ textDecoration: "none", color: "inherit" }}
                href="tel:884989249"
              >
                884 989 249
              </a>
            </span>
          </li>
          <li className="contactItem">
            <FiMail className="contactButtonIcon" />
            <span style={{ color: "var(--color-text-dark)" }}>
              <a
                href="mailto:bejgermanufaktura@gmail.com"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                bejgermanufaktura@gmail.com
              </a>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default NavigationMobile;
