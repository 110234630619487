import React from "react";
import { useNavigate } from "react-router-dom";
import introNext from "../assets/img/IntroNext.jpg";
import { useInView } from "react-intersection-observer";

function IntroNext() {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/gallery");
    window.scrollTo({ top: "0", behavior: "smooth" });
  };

  return (
    <div className="introNextDiv">
      <div className="container">
        <div className="introNext">
          <div className="introNextImg">
            <img
              style={{ display: "block", width: "100%" }}
              src={introNext}
              alt="Intro Kuchnia"
            />
          </div>
          <div
            className={inView ? "introNextTextbox" : "introNextTextbox hidden"}
          >
            <h3 className="headingIntroNext">
              Przeważająca większość naszych projektów stanowią kuchnie...
            </h3>
            <p className="paragraphIntroNext">
              ...ale coraz częściej zdarzają się zlecenia umeblowania całej
              przestrzeni mieszkalnej począwszy od wiatrołapu, czy korytarza, aż
              po garderobę, bibliotekę czy meble usytuowane w sypialni.
            </p>
            <button
              onClick={handleClick}
              ref={ref}
              className={`${
                inView ? "introNextButtonVisible" : "introNextButton"
              }`}
            >
              Zobacz nasze prace
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntroNext;
