import React from "react";

function CTA() {
  return (
    <section style={{ backgroundColor: "var(--color-accent)" }}>
      <div className="container">
        <div className="ctaWrapper">
          <h4 className="headingSmall">Masz pytania?</h4>
          <h2 className="ctaHeading">Napisz do nas, służymy pomocą!</h2>
          <div className="formWrapper">
            <form
              id="contact-form-cta"
              action="https://formspree.io/f/moqbkgwn"
              method="post"
              className="ctaForm"
            >
              <div className="ctaFormInputs">
                <input
                  type="text"
                  id="name-input-cta"
                  name="name-input-cta"
                  className="formInput"
                  placeholder="Imię"
                  autoComplete="off"
                  required
                />
                <input
                  type="email"
                  id="email-input-cta"
                  name="email-input-cta"
                  className="formInput"
                  placeholder="Email"
                  autoComplete="off"
                  required
                />
                <input
                  type="text"
                  id="subject-input-cta"
                  name="subject-input-cta"
                  className="formInput"
                  placeholder="Temat"
                  autoComplete="off"
                  required
                />
              </div>

              <textarea
                name="textarea"
                id="textarea-cta"
                className="formTextarea"
                placeholder="Twoja Wiadomość"
                rows={5}
              ></textarea>
            </form>
            <button
              form="contact-form-cta"
              type="submit"
              className="formButton"
            >
              Wyślij
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CTA;
