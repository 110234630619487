import React from "react";
// import logo from "../assets/img/logo.PNG";
import logo from "../assets/svg/bejgerLogo.svg";

function Logo() {
  return (
    <div
      className="logo"
      style={{
        maxWidth: "30rem",
        padding: "0 3rem",
      }}
    >
      <img style={{ width: "100%" }} src={logo} alt="Logo Bejger Manufaktura" />
    </div>
  );
}

export default Logo;
