import React, { useState } from "react";
import { BsArrowDown } from "react-icons/bs";

function NavCard({ name, img, link }) {
  const [isHovered, setIsHovered] = useState(false);
  const gradient = isHovered
    ? "rgba(29, 24, 21, 0.4)"
    : "rgba(29, 24, 21, 0.2)";

  return (
    <a
      href={link}
      className="navCard"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="cardContainer">
        <div
          className="cardImage"
          style={{
            backgroundImage: `linear-gradient(${gradient}, ${gradient}), url(${img})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            transition: "all 0.3s ease-in-out",
            scale: `${isHovered ? "1.1" : "1"}`,
          }}
        >
          <div className="cardTextbox">
            <p className="cardText">{name}</p>
            <BsArrowDown
              style={{
                height: "24px",
                width: "24px",
                color: "var(--color-text-white)",
                fontWeight: "600",
              }}
            />
          </div>
        </div>
      </div>
    </a>
  );
}

export default NavCard;
