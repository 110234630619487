import React from "react";
import Contact from "../components/Contact";

function Footer() {
  return (
    <footer className="footer">
      <Contact />
      <div className="copyright">
        <p className="copyParagraph">
          &copy; Copyright Bejger Manufaktura. Wszelkie prawa zastrzeżone |
          Stworzone przez{" "}
          <span>
            <a
              target="_blank"
              className="copyLink"
              rel="noreferrer"
              href="https://bartjozef.com"
            >
              Bartosz Józefowicz
            </a>
          </span>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
