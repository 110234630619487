import React from "react";

function FeatureCard({ icon, text }) {
  return (
    <div className="featCard">
      <div className="featIconDiv">{icon}</div>
      <p className="featText">{text}</p>
    </div>
  );
}

export default FeatureCard;
