import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function Navigation() {
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();

  const onClick = () => {
    setIsClicked((prevState) => {
      return prevState ? false : true;
    });
  };

  const updatePath = () => {
    if (window.location.pathname === "/gallery") navigate("/");
    else return;
  };

  return (
    <>
      {isClicked && (
        <motion.ul
          className="contactList"
          initial={{ translateY: -50, opacity: 0 }}
          animate={{ translateY: 0, opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.3 }}
        >
          <li className="contactItem">
            <BsFillTelephoneFill className="contactButtonIcon" />
            <span style={{ color: "var(--color-text-dark)" }}>
              <a
                style={{ textDecoration: "none", color: "inherit" }}
                href="tel:884989249"
              >
                884 989 249
              </a>
            </span>
          </li>
          <li className="contactItem">
            <FiMail className="contactButtonIcon" />
            <span style={{ color: "var(--color-text-dark)" }}>
              <a
                href="mailto:bejgermanufaktura@gmail.com"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                bejgermanufaktura@gmail.com
              </a>
            </span>
          </li>
        </motion.ul>
      )}

      <ul className="navigation">
        <li>
          <Link className="navigationLink" to="/">
            Strona Główna
          </Link>
        </li>
        <li>
          <a
            onClick={updatePath}
            href="#section-intro"
            className="navigationLink"
          >
            O nas
          </a>
        </li>
        <li>
          <a
            onClick={updatePath}
            href="#section-styles"
            className="navigationLink"
          >
            Style
          </a>
        </li>
        <li>
          <Link className="navigationLink" to="/gallery">
            Galeria
          </Link>
        </li>
        <li>
          <a
            onClick={updatePath}
            href="#section-difference"
            className="navigationLink"
          >
            Co nas odróżnia
          </a>
        </li>
        <li>
          <a href="#section-contact" className="navigationLink">
            Kontakt
          </a>
        </li>
        <button onClick={onClick} className="contactButton">
          <BsFillTelephoneFill className="contactButtonIcon" />
        </button>
      </ul>
    </>
  );
}

export default Navigation;
