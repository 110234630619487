import React from "react";
import fb from "../assets/img/fb.png";
import ig from "../assets/img/ig.png";

function SocialBar() {
  return (
    <div className="socialBar">
      <div className="container">
        <div className="socials">
          <div className="dividerBar"></div>
          <div className="socialsFB">
            <a
              className="socialLink"
              href="https://www.facebook.com/people/BejgerManufaktura/100084431062881/"
            >
              <img
                style={{ display: "block", width: "100%" }}
                src={fb}
                alt="Facebook Logo"
              />
            </a>
          </div>
          <div className="socialsIG">
            <a
              className="socialLink"
              href="https://www.instagram.com/bejgermanufaktura/?igshid=YmMyMTA2M2Y%3D"
            >
              <img
                style={{ display: "block", width: "100%" }}
                src={ig}
                alt="Instagram Logo"
              />
            </a>
          </div>
          <div className="dividerBar"></div>
        </div>
      </div>
    </div>
  );
}

export default SocialBar;
